<template>
  <modal name="info-submit-mobile-modal" class="info-submit-modal"
         height="95%" width="356" top="30">
    <div class="info-submit-modal-header">
      <img src="~@/assets/images/close-black.svg" class="close"
           @click="$modal.hide('info-submit-mobile-modal')"/>
      <div class="header-image"></div>
      <div class="header-contents">
        {{ $t('info_submit.header_content') }}
      </div>
    </div>
    <div class="info-submit-modal-body">
      <div class="form">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <validation-provider mode="eager" :rules="`required|email|checkEmail:${isValidEmail}`"
                               v-slot="{errors}">
            <md-field :class="{'md-invalid': errors[0]}">
              <label>Email</label>
              <md-input v-model="email" placeholder="Email"/>
              <span class="md-error sign-valid-message">*{{ errors[0] }}</span>
            </md-field>
          </validation-provider>
          <validation-provider rules="required|name|min:1|max:20" v-slot="{errors}">
            <md-field :class="{'md-invalid': errors[0]}">
              <label>Name</label>
              <md-input v-model="name" placeholder="Name"></md-input>
              <span class="md-error sign-valid-message">*{{ errors[0] }}</span>
            </md-field>
          </validation-provider>
          <validation-provider mode="eager" :rules="`required|mobile|checkMobile:${isValidMobile}`"
                               v-slot="{errors}">
            <md-field :class="{'md-invalid': errors[0]}">
              <label>Mobile</label>
              <md-input v-model="mobile" :value="mobile" placeholder="Mobile"
                        @keyup="replaceNotNumber"/>
              <span class="md-error sign-valid-message">*{{ errors[0] }}</span>
            </md-field>
          </validation-provider>
          <validation-provider rules="required|name|min:1|max:20" v-slot="{errors}">
            <md-field :class="{'md-invalid': errors[0]}">
              <label>Company Name</label>
              <md-input v-model="company" placeholder="Company Name"></md-input>
              <span class="md-error sign-valid-message">*{{ errors[0] }}</span>
            </md-field>
          </validation-provider>
          <validation-provider :rules="{ required: { allowFalse: false } }"
                               class="checkbox margin-36">
            <div class="block">
              <input type="checkbox" id="isPrivateTerms"
                     v-model="checkAgreement.isPrivateTerms"/>
              <label for="isPrivateTerms">
                <div class="img"/>
              </label>
              <div class="text">
                <a @click="$modal.show('privacy')">{{ $t('info_submit.privacy') }}</a>
              </div>
            </div>
          </validation-provider>
          <validation-provider :rules="{ required: { allowFalse: false } }" class="checkbox">
            <div class="block">
              <input type="checkbox" id="transferOversea"
                     v-model="checkAgreement.transferOversea"/>
              <label for="transferOversea">
                <div class="img"/>
              </label>
              <div class="text">
                <a @click="$modal.show('transfer')">{{ $t('info_submit.transfer') }}</a>
              </div>
            </div>
          </validation-provider>
          <validation-provider :rules="{ required: { allowFalse: true } }" class="checkbox">
            <div class="block">
              <input type="checkbox" id="providePrivateInfo"
                     v-model="checkAgreement.providePrivateInfo"/>
              <label for="providePrivateInfo">
                <div class="img"/>
              </label>
              <div class="text">
                <a @click="$modal.show('provide')">{{ $t('info_submit.provide') }}</a>
              </div>
            </div>
          </validation-provider>
          <validation-provider :rules="{ required: { allowFalse: true } }">
            <div class="block">
              <input type="checkbox" id="isSelectAllCheckBox" @change="selectAll"
                     v-model="isSelectAllCheckBox"/>
              <label for="isSelectAllCheckBox">
                <div class="img"/>
                <span>{{ $t('info_submit.select') }}</span>
              </label>
            </div>
          </validation-provider>
          <button class="submit-button" :disabled="invalid" @click.once="submitPersonalInfo">
            {{ $t('info_submit.check_detail') }}
          </button>
        </validation-observer>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TRIAL_FRONTEND_URL } from '@/common/config';

export default {
  name: 'InfoSubmitMobileModal',
  data() {
    return {
      name: '',
      email: '',
      mobile: '',
      company: '',
      isValidUsername: true,
      isValidEmail: true,
      isValidMobile: true,
      checkAgreement: {
        isPrivateTerms: false,
        transferOversea: false,
        providePrivateInfo: false,
      },
      isSelectAllCheckBox: false,
      isPasswordValid: false,
      checkedList: [],
    };
  },
  computed: {
    ...mapGetters(['trialSurvey']),
  },
  methods: {
    ...mapGetters(['participantToken']),
    ...mapActions(['savePersonalInfo']),
    selectAll() {
      const currentValue = this.isSelectAllCheckBox;
      this.checkAgreement = Object.keys(this.checkAgreement)
        .reduce((acc, key) => {
          acc[key] = currentValue;
          return acc;
        }, {});
    },
    replaceNotNumber(e) {
      const replacedValue = e.target.value.replace(/[^0-9\\.]+/g, '');
      e.target.value = replacedValue;
    },
    async submitPersonalInfo() {
      const providePrivateInfo = this.checkAgreement.providePrivateInfo ? 'Yes' : 'No';
      const personalInfo = {
        name: this.name,
        email: this.email,
        mobile: this.mobile,
        company: this.company,
        surveyId: this.trialSurvey.id,
        providePrivateInfo,
        participantToken: this.participantToken(),
        resultBaseUrl: `${TRIAL_FRONTEND_URL}/result/`,
      };
      const params = await this.savePersonalInfo(personalInfo);
      if (params) {
        this.$modal.hide('info-submit-mobile-modal');
        this.$emit('show', {
          name: this.name,
          email: this.email,
          mobile: this.mobile,
          company: this.company,
          surveyId: this.trialSurvey.id,
          participantToken: this.participantToken(),
        });
      }
    },
  },
  watch: {
    checkAgreement: {
      deep: true,
      handler() {
        /* eslint no-unused-vars: "off" */
        let count = 0;
        Object.entries(this.checkAgreement)
          .map(([key, value]) => {
            if (value === true) {
              count += 1;
            }
            return count;
          });
        if (count === 3) {
          this.isSelectAllCheckBox = true;
        } else {
          this.isSelectAllCheckBox = false;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.info-submit-modal {
  .info-submit-modal-header {
    .close {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }

    .header-image {
      margin: 73px auto 24px;
      width: 121px;
      height: 97px;
      background-size: 121px 97px;
      background-image: url('~@/assets/images/img-sally-assessment-level.svg');
    }

    .header-contents {
      font-size: 16px;
      font-weight: 300;
      text-align: center;
      color: #2a324b;
    }
  }

  .info-submit-modal-body {
    margin: 25px 48px 48px 48px;

    .form {
      .checkbox {
        display: block;
        margin-bottom: 13px;

        .block {
          display: flex;
          flex-direction: row;
        }

        .text {
          font-size: $font_size_14;

          a {
            cursor: pointer;
            text-decoration: underline;
            text-underline-position: under;
            color: initial;

            &:hover {
              color: $primary_color;
            }
          }

          margin-left: 6px;
        }
      }

      .margin-36 {
        margin-top: 36px;
      }
    }
  }

  .submit-button {
    margin-top: 36px;
    display: flex;
    justify-content: center;
    width: 258px;
    height: 40px;
    padding: 6px 45px;
  }
}
</style>
<style lang="scss">
@import '~@/assets/style/components/sally-modal.scss';

.vm--modal {
  overflow: auto;
}
</style>
