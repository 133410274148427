<template>
  <div class="lined-textarea">
    <label class="lined-textarea-title" :class="{focus: isFocus}">{{label}}</label>
    <textarea :disabled="disabled"
    :placeholder="placeholder"
    class="lined-textarea__content"
    :class="{ 'lined-textarea__content--disabled': disabled,
              'lined-textarea__content--wrap': !nowrap,
              'lined-textarea__content--nowrap': nowrap,
              'invalid': invalid,
              'focus': isFocus }"
    v-model="content"
    v-on:input="onInput"
    v-on:focus="isFocus=true"
    v-on:blur="isFocus=false"
    :style="styles"
    ref="textarea" />
  </div>
</template>

<script>
export default {
  name: 'SallyLinedTextarea',
  mounted() {
      this.content = this.value;
  },
  data() {
    return {
      content: '',
      isFocus: false,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    nowrap: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    styles: {
      type: Object,
      default() {
        return {
          height: '271px',
        };
      },
    },
    value: {
      type: String,
      default: '',
    },
    validate: {
      type: Function,
      default: () => true,
    },
    invalid: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    value(val) {
      if (val !== this.content) {
          this.content = val;
      }
    },
  },
  methods: {
    onInput() {
      this.$emit('input', this.content);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/variable.scss";

$border-radius: 3px;
$border-color: #dadce0;
$padding-top-bottom: 12px;
$padding-left-right: 14px;
.lined-textarea {
  display: flex;
  flex-direction: column;
  font-size: $font_size_14;
  line-height: 150%;
  width: 100%;
}
.lined-textarea-title{
  height: 13px;
  color: rgba(0, 0, 0, 0.42);
  font-size: 12px;
  margin-bottom: 10px;
  &.focus {
    color: $primary_color;
  }
}
.lined-textarea__lines {
  background-color: #f7f8fa;
  border-bottom: 1px solid $border-color;
  padding: $padding-top-bottom 10px $padding-top-bottom 15px;
  overflow: hidden;
  position: relative;
}
.lined-textarea__lines__inner {
  position: absolute;
  font-size: $font_size_14;
  letter-spacing: -0.35px;
  text-align: center;
  color: #707070;
}
.lined-textarea__lines__line {
  text-align: right;
  margin: 0;
}
.lined-textarea__lines__line--invalid {
  font-weight: bold;
  color: red;
}
.lined-textarea__content {
  margin: 0;
  line-height: inherit;
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid $border-color;
  padding: 0px;
  width: 100%;
  overflow: auto;
  &.focus {
    border-bottom: 1px solid $primary_color;
  }
  &.invalid {
    border-bottom: 1px solid red;
  }
}
.lined-textarea__content--wrap {
  white-space: pre-wrap;
  border-radius: 0px !important;
}
.lined-textarea__content--nowrap {
  white-space: pre;
  border-radius: 0px !important;
}
@supports (-ms-ime-align:auto) {
  .lined-textarea__content--nowrap {
    white-space: nowrap;
    border-radius: 0px !important;
  }
}
.lined-textarea__content--disabled {
  border-left-width: 1px;
  background-color: #FFFFFF;
  color: #4a4a4a;
  border-radius: 0px !important;
}
.lined-textarea__content:focus {
  outline: none;
}
</style>
