<template>
  <div ref="elementSelect" class="select-box" :class="{'select-box--active': isShowDropDown}">
    <div class="select-box__window" @click="onShowLanguage">
      {{ handleNameLanguage(currentLanguage) }}
    </div>
    <ul class="select-box__dropdown">
      <li v-for="(lang, index) in languages"
          :key="index"
          :class="{'active': currentLanguage === lang.language}"
          @click="onSelectLanguage(lang.language)">
        {{ handleNameLanguage(lang.language) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SelectLanguage',
  data() {
    return {
      isShowDropDown: false,
      currentLanguage: 'en',
      languages: [
        {
          language: 'en'
        },
        {
          language: 'ko'
        },
      ]
    };
  },
  created() {
    let language = localStorage.getItem('language');
    if (!language) {
      language = localStorage.setItem('language', this.currentLanguage);
    }
    this.currentLanguage = language;
    this.$i18n.locale = language;
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      if (!this.$refs.elementSelect.contains(event.target)) {
        this.isShowDropDown = false;
      }
    },
    onShowLanguage() {
      this.isShowDropDown = !this.isShowDropDown;
    },
    onSelectLanguage(language) {
      if (this.currentLanguage !== language) {
        this.currentLanguage = language;
        localStorage.setItem('language', this.currentLanguage);
        this.$i18n.locale = language;
      }
      this.isShowDropDown = false;
    },
    handleNameLanguage(key) {
      if (key === 'ko') {
        return 'Korean';
      }
      return 'English';
    }
  }
};
</script>

<style lang="scss" scoped>
.select-box {
  position: relative;
  font-size: 14px;
  color: #242424;
  width: 160px;

  &__window {
    position: relative;
    cursor: pointer;
    padding: 4px 12px 6px 40px;
    border: 1px solid #dadce0;
    border-radius: 2px;
    background-color: #FFF;
    background-image: url("~@/assets/images/ico_global.svg");
    background-position: left 12px center;
    background-repeat: no-repeat;
    background-size: 20px;

    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      background: url("~@/assets/images/ico_arrow_down.svg") center no-repeat;
    }

    &:hover {
      border: 1px solid #00ca94;
    }
  }

  &__dropdown {
    display: none;
    width: 100%;
    position: absolute;
    top: 32px;
    left: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .12);
    border-radius: 6px;
    padding: 0;
    margin: 0;
    list-style: none;
    overflow: hidden;

    li {
      cursor: pointer;
      width: 100%;
      padding: 5px 12px 7px 40px;
      background-color: #FFF;

      &:hover {
        background-color: #f6f6f7;
      }

      &.active {
        background-image: url("~@/assets/images/ico_check.svg");
        background-repeat: no-repeat;
        background-position: left 14px center;
        background-size: 12px;
      }
    }
  }

  &.select-box--active {
    .select-box__window {
      border: 1px solid #00ca94;

      &::after {
        transform:  translateY(-50%) rotate(180deg);
      }
    }

    .select-box__dropdown {
      display: block;
    }
  }
}
</style>
