<template>
  <sally-modal :name="name" width="800" height="573"
               class="privacy-modal">
    <template v-slot:title>
      {{title}}
    </template>
    <template v-slot:contents>
      <div class="contents">{{contents}}</div>
    </template>
    <template v-slot:buttons>
      <button @click="hide">{{ $t('confirm') }}</button>
    </template>
  </sally-modal>
</template>

<script>
    import SallyModal from '@/components/Common/SallyModal.vue';

    export default {
        name: 'PrivacyModal',
        components: {
            SallyModal,
        },
        props: {
            name: {
                type: String,
                required: true,
            },
            title: String,
            contents: String,
        },
        methods: {
            hide() {
                this.$modal.hide(this.name);
                this.$emit('hide');
            },
        },
    };
</script>

<style lang="scss">
  @import '~@/assets/style/variable.scss';

  .sally-modal.privacy-modal {
    .header {
      justify-content: center;
      font-weight: 500;
      font-size: $font_size_20;
    }
    .body {
      .contents {
        overflow: auto;
        width: 100%;
        height: 100%;
        padding: 30px 92px;
        white-space: pre-wrap;
      }
    }
    .footer {
      justify-content: center;
    }
  }
</style>
