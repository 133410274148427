<template>
  <div class="trial-result">
    <div v-if="isSurveySRM" class="trial-result--language">
      <select-language/>
    </div>
    <div class="mobile-header" v-if="isPhoneMode">
      <div class="bread">
        <span class="gray">{{ trialSurvey.name }}</span>
        <span class="slash">/</span>
        <span class="green">{{ $t('result.title') }}</span>
      </div>
      <div class="mobile-button" v-if="isPhoneMode && isInfoSubmitted">
        <div class="sharing" v-clipboard:copy='surveyUrl' @click="showMobileToast"/>
      </div>
    </div>
    <div class="scroll" :class="{'isSubmitted' : isPhoneMode && isInfoSubmitted}">
      <div class="wrapper">
        <div class="inner">
          <div v-if="!isPhoneMode" class="bread-crum">
            <span>{{ textHeader }}</span>
            <span class="slash">/</span>
            <span>{{ trialSurvey.name }}</span>
            <span class="slash">/</span>
            <span class="green">{{ $t('result.title') }}</span>
          </div>
          <div class="box">
            <div class="box-inner" id="print">
              <div v-if="!isPhoneMode" class="button-group"
                   data-html2canvas-ignore="true">
                <div class="button" @click="download"
                     :class="{ 'not-show': isIE || !isInfoSubmitted }">
                  <img src="~@/assets/images/ico-download-hover.svg"/>
                  <span>{{ $t('result.download') }}</span>
                </div>
                <div class="button" :class="{ 'not-show': !isInfoSubmitted }"
                     v-clipboard:copy='surveyUrl' @click="showToast">
                  <img src="~@/assets/images/ico-share-trial-desktop.svg"/>
                  <span>{{ $t('result.share') }}</span>
                </div>
              </div>
              <div class="title">
                <div :class="[`default-level-${levelIndex}`]"></div>
                <div class="text">{{ levelHeadline }}</div>
              </div>
              <div v-if="!isPhoneMode" class="level-group">
                <div class="bar">
                  <div class="bar-text" :class="{active: index === levelIndex}"
                       v-for="(levelDescription, index) in levelDescriptions" :key="index">
                    {{ levelDescription }}
                  </div>
                </div>
                <div class="contents">
                  <div class="content" :class="{active: index === levelIndex}"
                       v-for="(logic, index) in levelLogics" :key="index">
                    <div>{{ logic }}</div>
                  </div>
                </div>
              </div>
              <div v-else-if="isPhoneMode" class="level-group">
                <div>
                  <div v-for="(levelDescription, descriptionIndex) in levelDescriptions"
                       :key="descriptionIndex">
                    <div v-if="descriptionIndex === levelIndex" class="bar">
                      {{ levelDescription }}
                    </div>
                  </div>
                </div>
                <div>
                  <div v-for="(logic, logicIndex) in levelLogics" :key="logicIndex">
                    <div v-if="logicIndex === levelIndex" class="content">
                      <div>{{ logic }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isNotShowReport" class="blured-area">
              <div class="blured-image"/>
              <button v-if="!isPhoneMode" class="submit-button"
                      @click="openModal()">
                {{ $t('result.check_detail') }}
              </button>
            </div>
            <div v-else>
              <div class="graph">
                <trial-labs-radar-chart :data="labsRadarChart"/>
              </div>
              <div class="result">
                <div class="header"> {{ $t('result.analysis_report') }}</div>
                <div class="sub-header" v-if="isExplanationExist">{{ levelExplanationLong }}</div>
                <div class="body">
                  <div class="category" v-for="(category, index) in
                mySubmissionResult.trialCategoryDiagnoses" :key="index">
                    <div class="category-container" v-if="isDescriptionExist(category)">
                      <div class="name">{{ category.categoryName }}</div>
                      <div class="descriptions">
                        <div class="description" v-for="(description, descIndex)
                        in category.description" :key="descIndex">{{ description }}
                        </div>
                        <div v-show="showGuide(category)">
                          <div class="guide-header">
                            <img src="~@/assets/images/ico_guide.svg"/>
                            <span>{{ $t('result.advice') }}</span>
                          </div>
                          <div class="category-guide">{{ category.categoryGuide }}</div>
                          <div class="question-guides">
                            <div class="guide" v-for="(guide, guideIndex)
                          in category.questionGuides" :key="guideIndex">{{ guide }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-trial">
                <div class="bottom" :class="{short: !isShowConsulting}">
                  <div class="my-trial-box" v-if="isShowConsulting">
                    <div class="img solution"></div>
                    <div class="right">
                      <div class="bold">{{ $t('result.contact_our') }}<br v-if="!isPhoneMode"/>
                        <span v-if="isKorean">상담하기</span>
                      </div>
                      <div class="button" @click="requestConsulting">{{
                          $t('result.contact_us')
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="my-trial-box">
                    <div class="img share"></div>
                    <div class="right">
                      <div class="bold">{{ $t('result.solutions') }} <br v-if="!isPhoneMode"/>
                        <span v-if="isKorean">DT offering 보기</span>
                      </div>
                      <div class="button" @click="showDTOffering">{{
                          $t('result.learn_more')
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-container">
                  <div v-if="!isSurveySRM" class="retry-text" @click="openFreeTrial">{{
                      $t('result.assessments')
                    }}
                    <span v-if="!isPhoneMode"> | </span>
                  </div>
                  <div class="retry-text" @click="retry">{{ $t('result.redo') }}</div>
                </div>
              </div>
            </div>
          </div>
          <copyright/>
        </div>
      </div>
    </div>
    <div v-if="isPhoneMode" class="direction-buttons" :class="{'hide': isInfoSubmitted }">
      <button v-if="isShowDetailedReport" class="submit-button"
              @click="openModalInMobile()">
        {{ $t('result.detailed_report') }}
      </button>
    </div>
    <info-submit-modal @show="showContents"/>
    <info-submit-mobile-modal @show="showContents"/>
    <request-consulting-modal @hide="requestConsulting"
                              @submitSuccessModal="submitSuccessModal"
                              :surveyTitle="trialSurvey.name"
                              :participantInfo="participantInfo"/>
    <request-consulting-mobile-modal @hide="requestConsulting"
                                     @submitSuccessModal="submitSuccessModal"
                                     :surveyTitle="trialSurvey.name"/>
    <submit-success-modal/>
    <submit-success-mobile-modal/>
    <privacy-modal name="privacy"
                   :title="TERMS_OF_PRIVATE_INFORMATION_TITLE"
                   :contents="TERMS_OF_PRIVATE_INFORMATION_CONTENTS"/>
    <privacy-modal name="transfer"
                   :title="TERMS_OF_TRANSFER_TITLE"
                   :contents="TERMS_OF_TRANSFER_CONTENTS"/>
    <privacy-modal name="provide"
                   :title="TERMS_OF_PROVIDE_TITLE"
                   :contents="TERMS_OF_PROVIDE_CONTENTS"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import html2canvas from 'html2canvas';
import {
  TRIAL_FRONTEND_URL,
  MYTRIAL_URL,
  DT_ASSESSMENT_PAGE,
  SURVEY_ID_SRM
} from '@/common/config';
import { getSurveyParams, setUserParams, getUserParams } from '@/common/local-storage';
import Copyright from '@/components/Common/Copyright.vue';
import PrivacyModal from '@/components/Modal/TermsModal.vue';
import { isInternetExplore, isMobile } from '@/common/browser.util';
import TrialLabsRadarChart from '@/components/Result/TrialLabsRadarChart.vue';
import RequestConsultingModal from '@/components/Modal/RequestConsultingModal.vue';
import RequestConsultingMobileModal from '@/components/Modal/RequestConsultingMobileModal.vue';
import SubmitSuccessModal from '@/components/Modal/SubmitSuccessModal.vue';
import SubmitSuccessMobileModal from '@/components/Modal/SubmitSuccessMobileModal.vue';
import InfoSubmitModal from '@/components/Modal/InfoSubmitModal.vue';
import InfoSubmitMobileModal from '@/components/Modal/InfoSubmitMobileModal.vue';
import pdfDownload, { convertFileToDataURL } from '@/common/pdf.util';
import poweredBySallyImage from '@/assets/images/Powered by Sally@3x.png';
import samsungSDSImage from '@/assets/images/logo-samsung@3x.png';
import icoGuideImage from '@/assets/images/ico_guide_18x18@3x.png';
import copyrightImage from '@/assets/images/copyright-pdf.png';
import bus from '@/common/bus.util';
import {
  TERMS_OF_PRIVATE_INFORMATION_TITLE,
  TERMS_OF_PRIVATE_INFORMATION_CONTENTS,
  TERMS_OF_TRANSFER_TITLE,
  TERMS_OF_TRANSFER_CONTENTS,
  TERMS_OF_PROVIDE_TITLE,
  TERMS_OF_PROVIDE_CONTENTS,
} from '@/assets/js/text';
import SelectLanguage from '@/components/Common/SelectLanguage.vue';

export default {
  name: 'Result',
  components: {
    SelectLanguage,
    PrivacyModal,
    TrialLabsRadarChart,
    Copyright,
    InfoSubmitModal,
    InfoSubmitMobileModal,
    RequestConsultingModal,
    RequestConsultingMobileModal,
    SubmitSuccessModal,
    SubmitSuccessMobileModal,
  },
  data() {
    return {
      average: 0,
      radarChartData: [],
      levelIndex: 0,
      service: [],
      serviceLink: [],
      isIE: false,
      isInfoSubmitted: false,
      submittedPersonalInfo: {},
      participantInfo: {},
      isShowConsulting: false,
      TERMS_OF_PRIVATE_INFORMATION_TITLE,
      TERMS_OF_PRIVATE_INFORMATION_CONTENTS,
      TERMS_OF_TRANSFER_TITLE,
      TERMS_OF_TRANSFER_CONTENTS,
      TERMS_OF_PROVIDE_TITLE,
      TERMS_OF_PROVIDE_CONTENTS,
    };
  },
  async mounted() {
    bus.$emit('start:spinner');

    await this.fetchTrialSurveyWithMixedId(this.$route.params.id);

    if (this.isFreePass) {
      await this.getFpSubmissionResult(this.$route.params.id);
    } else {
      await this.getMyLastSubmissionResult(this.$route.params.id);
    }
    bus.$emit('end:spinner');
    if (this.doesNotSubmitCustomerInfo) {
      const surveyParams = getSurveyParams();
      if (!surveyParams) {
        window.location.href = `${TRIAL_FRONTEND_URL}/survey/${this.trialSurvey.id}`;
      } else {
        window.location.href = `${TRIAL_FRONTEND_URL}/survey/${this.trialSurvey.id}?utm_source=${surveyParams.survey.utmSource}&utm_medium=${surveyParams.survey.utmMedium}&utm_campaign=${surveyParams.survey.utmCampaign}&utm_content=${surveyParams.survey.utmContent}`;
      }
    }
    this.average = this.mySubmissionResult.average;
    this.levelResult();
    this.isIE = isInternetExplore();
    this.isInfoSubmitted = this.mySubmissionResult.personalDataSubmitted;

    // 브라우저 뒤로 가기 버튼 클릭시
    window.onpopstate = () => {
      if (!getSurveyParams().survey.utmSource) {
        window.location.href = `${TRIAL_FRONTEND_URL}/survey/${getSurveyParams().survey.id}`;
      } else {
        window.location.href = `${TRIAL_FRONTEND_URL}/survey/${getSurveyParams().survey.id}?utm_source=${getSurveyParams().survey.utmSource}&utm_medium=${getSurveyParams().survey.utmMedium}&utm_campaign=${getSurveyParams().survey.utmCampaign}&utm_content=${getSurveyParams().survey.utmContent}`;
      }
    };
    const user = getUserParams();
    if (user) {
      this.isShowConsulting = true;
    }
  },
  computed: {
    ...mapGetters(['trialSurvey', 'trialSubmissionId', 'mySubmissionResult', 'participantToken', 'entryPoint']),
    textHeader() {
      return this.trialSurvey.id === SURVEY_ID_SRM ? 'SRM Competency Assessment' : 'Digital Transformation Assessment';
    },
    isSurveySRM() {
      return this.trialSurvey.id === SURVEY_ID_SRM;
    },
    isMobile,
    isPhoneMode() {
      if (window.screen.width < 700) {
        return true;
      }
      return this.isMobile && !this.isFreePass;
    },
    isKorean() {
      return this.$i18n.locale.includes('ko');
    },
    labsRadarChart() {
      return this.mySubmissionResult.trialCategoryDiagnoses
        .map(category => ({
          category: category.categoryName,
          average: category.average === -1 ? 0 : this.$formatValue(category.average),
        }));
    },
    levelHeadline() {
      if (this.mySubmissionResult.trialLevels !== undefined) {
        return this.mySubmissionResult.trialLevels.map(level => level.headline)[this.levelIndex];
      }
      return [];
    },
    levelDescriptions() {
      if (this.mySubmissionResult.trialLevels !== undefined) {
        return this.mySubmissionResult.trialLevels.map(level => level.description);
      }
      return [];
    },
    levelLogics() {
      if (this.mySubmissionResult.trialLevels !== undefined) {
        return this.mySubmissionResult.trialLevels.map(level => level.logic);
      }
      return [];
    },
    levels() {
      if (this.mySubmissionResult.trialLevels !== undefined) {
        return this.mySubmissionResult.trialLevels.map(level => level.level);
      }
      return [];
    },
    levelExplanationLong() {
      return this.mySubmissionResult.trialLevels[this.levelIndex].explanationLong;
    },
    isExplanationExist() {
      if (this.mySubmissionResult.trialLevels === undefined) return false;
      return this.mySubmissionResult.trialLevels[this.levelIndex].explanationLong !== '';
    },
    isServiceExist() {
      if (this.mySubmissionResult.trialLevels === undefined) return false;
      if (this.mySubmissionResult.trialLevels[this.levelIndex].serviceLink === '') return false;
      return this.mySubmissionResult.trialLevels[this.levelIndex].service !== '';
    },
    surveyUrl() {
      return `${TRIAL_FRONTEND_URL}/result/${this.$route.params.id}`;
    },
    isFreePass() {
      return this.participantToken.startsWith('FREEPASS');
    },
    doesNotSubmitCustomerInfo() {
      return this.isFreePass
        && !this.mySubmissionResult.personalDataSubmitted && !this.participantToken;
    },
    isNotShowReport() {
      if (this.isFreePass) {
        return false;
      }
      return !this.isInfoSubmitted;
    },
    isShowDetailedReport() {
      if (this.isFreePass) {
        return false;
      }
      return isMobile && !this.isInfoSubmitted;
    },
    isShow() {
      if (this.isFreePass) {
        return true;
      }
      return !this.isMobile;
    },
    isShowMobileButton() {
      if (this.isFreePass) {
        return false;
      }
      return isMobile && this.isInfoSubmitted;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'intro' || to.name === 'trial') {
      next(false);
    } else {
      next();
    }
  },
  methods: {
    ...mapActions(['fetchTrialSurveyWithMixedId', 'getMyLastSubmissionResult', 'fetchTrialSurveyById', 'getFpSubmissionResult']),
    async retry() {
      if (this.isFreePass) {
        window.location.href = `${TRIAL_FRONTEND_URL}/fp/${this.mySubmissionResult.surveyId}`;
      } else {
        window.location.href = `${TRIAL_FRONTEND_URL}/survey/${this.mySubmissionResult.surveyId}`;
      }
    },
    openFreeTrial() {
      window.open(`${DT_ASSESSMENT_PAGE}`, '_blank');
    },
    async requestConsulting() {
      const user = getUserParams();
      if (user) {
        this.isShowConsulting = true;
      }
      if (this.trialSurvey.id !== SURVEY_ID_SRM) {
        if (this.isMobile || window.screen.width < 700) {
          this.$modal.show('request-consulting-mobile-modal', user);
        } else {
          this.$modal.show('request-consulting-modal', user);
        }
      } else {
        window.open('https://lp.mkt-email.samsungsds.com/SamsungSRM-Form', '_blank');
      }
    },
    openSolutionDetail() {
      const solutionKey = this.mySubmissionResult.solutionKey;
      if (solutionKey) {
        const url = `${MYTRIAL_URL}/#/solutionDetail/${solutionKey}?consulting=detail`;
        window.open(url, '_blank');
      } else {
        window.open(MYTRIAL_URL, '_blank');
      }
    },
    isDescriptionExist(category) {
      const emptyDescription = category.description.filter(d => d !== '');
      return emptyDescription.length > 0;
    },
    levelResult() {
      /* eslint no-eval: "off" */
      const scoreLogic = this.mySubmissionResult.trialLevels.map(level => level.scoreLogic);
      for (let i = 0; i < scoreLogic.length; i += 1) {
        const tmpLogic = scoreLogic[i];
        if (eval(tmpLogic)) {
          this.levelIndex = i;
          if (this.isServiceExist) {
            this.service = this.mySubmissionResult.trialLevels[this.levelIndex].service.split(',');
            this.serviceLink = this.mySubmissionResult.trialLevels[this.levelIndex].serviceLink.split(',');
          }
          break;
        }
      }
    },
    async download() {
      let text = '';
      if (this.trialSurvey.id === SURVEY_ID_SRM) {
        text = 'SRM Competency Assessment';
      } else {
        text = 'Digital Transformation Assessment';
      }
      const header = this.getHeader(`${text} / ${this.trialSurvey.name}`);
      const footer = this.getFooter(
        {
          image1: await convertFileToDataURL(samsungSDSImage),
          image2: await convertFileToDataURL(poweredBySallyImage),
          image3: await convertFileToDataURL(copyrightImage),
        },
      );
      const content1 = document.getElementById('print');
      const content2 = this.mySubmissionResult.trialCategoryDiagnoses;
      const content = await this.getContent(content1, content2);
      pdfDownload('Trial 결과', header, footer, content);
    },
    getHeader(header) {
      return {
        text: header,
        color: '#2a324b',
        fontSize: 14,
        margin: [45, 52],
      };
    },
    getFooter({ image1, image2, image3 }) {
      return {
        columns: [
          {
            columns: [
              {
                image: image1,
                width: 136,
                height: 17,
              },
              {
                image: image2,
                width: 107,
                height: 17,
              },
            ],
            columnGap: 4,
            margin: [45, 37],
          },
          {
            image: image3,
            alignment: 'right',
            width: 324,
            height: 17,
            margin: [15, 37],
          },
        ],
      };
    },
    async getContent(content1, content2) {
      const image = await convertFileToDataURL(icoGuideImage);
      const canvas = await html2canvas(content1);
      const dataUrl = canvas.toDataURL();
      const title = {
        text: 'Analysis Report',
        alignment: 'center',
        bold: true,
        fontSize: 24,
        margin: [0, 72, 0, 20],
      };
      const subTitle = this.levelExplanationLong ? {
        text: this.levelExplanationLong,
        alignment: 'left',
        fontSize: 16,
        margin: [96, 0, 96, 48],
      } : null;
      const contents = content2
        .filter(c => c.description.filter(d => d).length > 0)
        .map((c) => {
          const category = {
            text: c.categoryName,
            fontSize: 16,
            alignment: 'right',
            bold: true,
            width: 328,
            margin: [168, 0, 23, 0],
          };
          const categoryDescription = [];
          const descriptions = c.description.map(description => ({
            text: description,
            fontSize: 16,
            margin: [0, 0, 168, 0],
          }));

          const questionGuides = c.questionGuides.map(q => ({
            text: q,
            fontSize: 16,
            margin: [0, 0, 168, 0],
          }));
          const guide = [
            {
              margin: [0, 24, 0, 24],
              canvas: [
                {
                  type: 'line',
                  lineWidth: 1,
                  x1: 0,
                  y1: 0,
                  x2: 710,
                  y2: 0,
                  dash: { length: 5, space: 5 },
                },
              ],
            },
            {
              margin: [0, 0, 168, 0],
              columns: [
                {
                  image,
                  width: 18,
                },
                {
                  text: '한줄가이드',
                  fontSize: 16,
                  margin: [4, 0, 0, 0],
                },
              ],
            },
            {
              text: c.categoryGuide,
              fontSize: 16,
              margin: [0, 0, 168, 0],
            },
            ...questionGuides,
          ];
          categoryDescription.push(...descriptions);
          if (this.showGuide(c)) {
            categoryDescription.push(...guide);
          }
          return [
            {
              columns: [
                category,
                [
                  ...categoryDescription,
                ],
              ],
            },
            {
              margin: [168, 24, 0, 24],
              canvas: [
                {
                  type: 'line',
                  lineWidth: 1,
                  x1: 0,
                  y1: 0,
                  x2: 864,
                  y2: 0,
                  // dash: { length: 5, space: 10 },
                },
              ],
            },
          ];
        });

      const explanation = [];
      if (subTitle || contents.length > 0) {
        explanation.push(title);
      }
      if (subTitle) {
        explanation.push(subTitle);
      }
      if (contents.length > 0) {
        explanation.push(...contents);
      }
      return [
        {
          image: dataUrl,
          alignment: 'center',
          width: 1200,
        },
        ...explanation,
      ];
    },
    showDTOffering() {
      let url = '';
      if (this.trialSurvey.id === SURVEY_ID_SRM) {
        url = 'https://www.samsungsds.com/us/srm/srm.html';
      } else {
        url = this.mySubmissionResult.trialLevels[this.levelIndex].serviceLink;
      }
      if (url.length > 0) {
        window.open(url, '_blank');
      }
    },
    showGuide(category) {
      return category.categoryGuide || category.questionGuides.some(q => q !== '');
    },
    openModal() {
      this.$modal.show('info-submit-modal');
    },
    openModalInMobile() {
      this.$modal.show('info-submit-mobile-modal');
    },
    showContents(user) {
      setUserParams(user);
      this.isShowConsulting = true;
      this.isInfoSubmitted = true;
    },
    submitSuccessModal() {
      if (this.isMobile || window.screen.width < 700) {
        this.$modal.show('submit-success-mobile-modal');
      } else {
        this.$modal.show('submit-success-modal');
      }
    },
    showToast() {
      this.$toasted.show(`
        <div class="sally-trial-toast">
          <div class="image"></div>
          <span>${this.$t('result.toast_copied')}</span>
        </div>
      `, {
        position: 'below-button',
        duration: 3000,
      });
    },
    showMobileToast() {
      this.$toasted.show(`
      <div class="sally-trial-toast">
        <div class="image"></div>
        <span>${this.$t('result.toast_copied')}</span>
      </div>
    `, {
        position: 'top-center',
        duration: 3000,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.trial-result {
  width: 100%;
  min-width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  overflow: auto;

  &--language {
    position: absolute;
    z-index: 9;
    top: 20px;
    display: flex;
    justify-content: flex-end;
    max-width: 1180px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .scroll {
    width: 100%;
    height: 100%;

    .wrapper {
      padding: $standard_padding_size 0 0 0;
      max-width: $max_width;
      margin: auto;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .inner {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        align-items: center;

        .bread-crum {
          display: flex;
          align-self: flex-start;
          font-size: $font_size_16;
          font-weight: normal;
          line-height: 1.38;
          letter-spacing: -0.4px;
          color: #4a4a4a;

          .slash {
            margin: 0 5px;
          }

          .green {
            font-weight: 500;
            color: $primary_color;
          }
        }

        .box {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
          border: solid 1px #ededed;
          margin-top: 38px;

          .blured-area {
            width: 100%;
            position: relative;

            .blured-image {
              width: 100%;
              min-height: 2036px;
              background-repeat: no-repeat;
              background-position: center;
              background-image: url('~@/assets/images/img-blurred-result.png');
            }

            .submit-button {
              position: absolute;
              top: 143px;
              left: calc((100% - 278px) / 2);
              width: 278px;
              height: 40px;
              padding: 6px 45px;
              background-color: #00ca94;
              font-size: 21px;
              font-weight: 500;
              color: #ffffff;
            }
          }

          .box-inner {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .button-group {
            display: flex;
            flex-direction: row;
            align-self: flex-end;

            .button {
              margin-top: 27px;
              margin-right: 27px;
              font-size: $font_size_14;
              line-height: 0.93;
              letter-spacing: -0.35px;
              color: $primary_color;
              cursor: pointer;

              img {
                margin-right: 5px;
                width: 15px;
              }

              &.not-show {
                visibility: hidden;
              }
            }

            .button + .button {
              margin-left: 4px;
            }
          }

          .title {
            margin-top: 58px;
            margin-bottom: 36px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .image {
              width: 224px;
              height: 195px;
              background-repeat: no-repeat;
              background-size: 224px 195px;
              background-image: url("~@/assets/images/trial_submission_result.svg");
            }

            .default-level-0 {
              width: 224px;
              height: 195px;
              background-repeat: no-repeat;
              background-size: 224px 195px;
              background-image: url("~@/assets/images/img_default_level1.svg");
            }

            .default-level-1 {
              width: 224px;
              height: 195px;
              background-repeat: no-repeat;
              background-size: 224px 195px;
              background-image: url("~@/assets/images/img_default_level2.svg");
            }

            .default-level-2 {
              width: 224px;
              height: 195px;
              background-repeat: no-repeat;
              background-size: 224px 195px;
              background-image: url("~@/assets/images/img_default_level3.svg");
            }

            .default-level-3 {
              width: 224px;
              height: 195px;
              background-repeat: no-repeat;
              background-size: 224px 195px;
              background-image: url("~@/assets/images/img_default_level4.svg");
            }

            .default-level-4 {
              width: 224px;
              height: 195px;
              background-repeat: no-repeat;
              background-size: 224px 195px;
              background-image: url("~@/assets/images/img_default_level5.svg");
            }

            .text {
              font-size: $font_size_28;
              font-weight: 500;
              line-height: 1.35;
              letter-spacing: normal;
              color: #2a324b;
              margin-top: 24px;
              white-space: pre;
              text-align: center;
            }
          }

          .level-group {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 898px;

            .bar {
              display: flex;
              align-items: center;
              width: 100%;
              height: 42px;
              border-radius: 50px;
              background-color: rgba(0, 202, 148, 0.1);
              padding: 4px;

              .bar-text {
                display: inline-flex;
                height: 100%;
                flex: 1;
                font-size: $font_size_18;
                font-weight: $font_weight_400;
                letter-spacing: normal;
                color: #5cddbb;
                justify-content: center;
                align-items: center;
                line-height: 1.11;

                &.active {
                  font-weight: 600;
                  color: #ffffff;
                  background-color: $primary_color;
                  border-radius: 50px;
                }
              }
            }

            .contents {
              margin-top: 6px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              flex-shrink: 0;
              width: 100%;

              .content {
                flex: 1;
                flex-grow: 1;
                max-width: 20%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                flex-shrink: 0;
                align-items: center;
                padding: 18px 9px;
                font-size: $font_size_16;
                font-weight: $font_weight_400;
                line-height: 20px;
                letter-spacing: -0.3px;
                text-align: center;
                word-break: keep-all;
                color: rgba(42, 50, 75, 0.6);

                & > div {
                  width: 100%
                }

                .level {
                  font-weight: 500;
                  line-height: 1.55;
                  letter-spacing: normal;
                  margin-bottom: 6px;
                  color: rgba(42, 50, 75, 0.6);
                }

                &.active {
                  border-radius: 5px;
                  border: solid 1px #00ca94;
                  background-color: #e5faf4;
                  color: #2a324b;
                  font-weight: normal;

                  .level {
                    font-weight: 600;
                    color: #00ca94;
                  }
                }
              }
            }
          }

          .graph {
            width: 800px;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
            padding-top: 60px;
          }

          .result {
            display: flex;
            flex-direction: column;
            margin-top: 72px;
            padding: 0 80px;

            .header {
              font-size: $font_size_24;
              font-weight: bold;
              line-height: 20px;
              letter-spacing: normal;
              text-align: center;
              color: #2a324b;
            }

            .sub-header {
              margin-top: 36px;
              font-size: $font_size_20;
              font-weight: 400;
              line-height: 28px;
              letter-spacing: normal;
              text-align: center;
              color: #2a324b;
            }

            .body {
              display: flex;
              flex-direction: column;
              margin-top: 48px;

              .category {
                &:nth-child(2n-1) {
                  background-color: rgba(0, 202, 148, 0.1);
                }

                .category-container {
                  display: flex;
                  flex-direction: row;
                  padding: 24px 0;

                  .name {
                    width: 239px;
                    height: 100%;
                    font-size: $font_size_20;
                    padding: 0px 0 20px 39px;
                    font-weight: 500;
                    line-height: 1.35;
                    letter-spacing: normal;
                    text-align: right;
                    color: #2a324b;
                  }

                  .descriptions {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    align-self: center;
                    margin: 0 23px;
                    color: #2a324b;
                    letter-spacing: normal;
                    text-align: left;
                    word-break: keep-all;
                    white-space: pre-wrap;

                    .description {
                      font-size: $font_size_18;
                      font-weight: $font_weight_400;
                      line-height: 1.33;
                    }

                    .guide-header {
                      margin-top: 24px;
                      border-top: dashed 1px #2a324b;
                      padding-top: 24px;
                      font-size: $font_size_16;
                      font-weight: 500;
                      line-height: 1.5;

                      img {
                        margin-right: 4px;
                      }
                    }

                    .category-guide {
                      font-size: $font_size_16;
                      font-weight: 500;
                      line-height: 1.5;
                      letter-spacing: normal;
                      margin-top: 7px;
                    }

                    .question-guides {
                      margin-top: 10px;
                      font-size: $font_size_16;
                      font-weight: 300;
                      line-height: 1.5;
                    }
                  }
                }
              }
            }

            .footer {
              margin-top: 72px;
              display: flex;
              flex-direction: column;
              align-items: center;

              .service {
                font-size: $font_size_24;
                font-weight: bold;
                line-height: 0.83;
                letter-spacing: normal;
                text-align: center;
                color: #2a324b;
                margin-bottom: 18px;
              }

              .service-link {
                a {
                  font-size: $font_size_20;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 36px;
                  letter-spacing: normal;
                  text-align: center;
                  text-decoration: underline;
                  color: #2a324b;
                }
              }
            }
          }

          .my-trial {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 80px;

            .bottom {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 100%;

              &.short {
                justify-content: center;

                .my-trial-box {
                  max-width: 466px;
                }
              }

              .my-trial-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 48px;
                border-radius: 8px;
                border: solid 1px #cccccc;
                width: 100%;

                .img {
                  margin: 48px 36px 48px 48px;
                  background-image: url("~@/assets/images/my_trial_diagnosis.svg");
                  background-size: 120px 90px;
                  width: 120px;
                  min-width: 120px;
                  height: 90px;

                  &.solution {
                    background-image: url("~@/assets/images/img_dt_consulting.svg");
                  }

                  &.share {
                    background-image: url("~@/assets/images/img_dt_offering.svg");
                  }
                }

                .right {
                  font-size: $font_size_16;
                  line-height: 1.63;
                  letter-spacing: normal;
                  color: #2a324b;
                  margin: 40px 0;

                  .bold {
                    font-size: $font_size_23;
                    font-weight: bold;
                    line-height: 1.35;
                    margin-bottom: 32px;
                  }

                  .button {
                    width: 100px;
                    height: 32px;
                    line-height: 32px;
                    background-color: #00ca94;
                    font-size: $font_size_13;
                    text-align: center;
                    cursor: pointer;
                    color: #ffffff;
                  }
                }
              }

              .my-trial-box + .my-trial-box {
                margin-left: 48px;
              }
            }

            .text-container {
              display: flex;
              margin-top: 48px;
              margin-bottom: 72px;
              white-space: pre-wrap;

              .retry-text {
                font-size: $font_size_16;
                letter-spacing: normal;
                color: #2a324b;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }

        .copyright {
          margin-top: 53px;
        }
      }
    }
  }
}

@media only screen and (max-width: $max_width_size_for_mobile) {
  .trial-result {
    height: 100%;
    width: 100%;
    position: relative;

    .mobile-header {
      position: absolute;
      width: 100%;
      top: 0;
      display: flex;
      justify-content: space-between;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      z-index: 999;

      .bread {
        margin-top: 18px;
        margin-bottom: 18px;
        margin-left: 18px;
        font-size: $font_size_14;
        color: #4a4a4a;

        .gray {
          color: #4a4a4a;
        }

        .slash {
          margin: 0 5px;
        }

        .green {
          align-self: center;
          color: $primary_color;
        }
      }

      .mobile-button {
        display: flex;
        flex-direction: row;
        margin-right: 18px;
        align-items: center;

        .download {
          width: 32px;
          height: 32px;
          margin-right: 12px;
          background-repeat: no-repeat;
          background-size: 32px 32px;
          background-image: url("~@/assets/images/ico_download_trial_mobile.svg");
        }

        .sharing {
          width: 32px;
          height: 32px;
          background-repeat: no-repeat;
          background-size: 32px 32px;
          background-image: url("~@/assets/images/ico_share_trial_mobile.svg");
        }
      }
    }

    .scroll {
      position: absolute;
      top: 54px;
      overflow: auto;
      width: 100%;
      height: calc(100% - 102px);
      overflow-x: hidden;

      .wrapper {
        padding: 0px;

        .inner {
          padding-bottom: 53px;

          .box {
            margin-top: 0px;
            border-radius: 0px;
            box-shadow: none;
            border: none;

            .blured-area {
              width: 100%;
              position: relative;

              .blured-image {
                width: 100%;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url('~@/assets/images/img-mobile-blurred.png');
              }
            }

            .title {
              margin: 36px 36px 24px;

              .image {
                width: 200px;
                height: 174px;
                background-repeat: no-repeat;
                background-size: 200px 174px;
                background-image: url("~@/assets/images/trial_submission_result.svg");
              }

              .default-level-0 {
                width: 200px;
                height: 174px;
                background-repeat: no-repeat;
                background-size: 200px 174px;
                background-image: url("~@/assets/images/img_default_level1.svg");
              }

              .default-level-1 {
                width: 200px;
                height: 174px;
                background-repeat: no-repeat;
                background-size: 200px 174px;
                background-image: url("~@/assets/images/img_default_level2.svg");
              }

              .default-level-2 {
                width: 200px;
                height: 174px;
                background-repeat: no-repeat;
                background-size: 200px 174px;
                background-image: url("~@/assets/images/img_default_level3.svg");
              }

              .default-level-3 {
                width: 200px;
                height: 174px;
                background-repeat: no-repeat;
                background-size: 200px 174px;
                background-image: url("~@/assets/images/img_default_level4.svg");
              }

              .default-level-4 {
                width: 200px;
                height: 174px;
                background-repeat: no-repeat;
                background-size: 200px 174px;
                background-image: url("~@/assets/images/img_default_level5.svg");
              }

              .text {
                font-size: $font_size_28;
                font-weight: 500;
                color: #2a324b;
                margin-top: 24px;
                white-space: pre-wrap;
                text-align: center;
              }
            }

            .level-group {
              .bar {
                display: flex;
                align-items: center;
                height: 34px;
                width: 178px;
                justify-content: center;
                margin: 0 0 12px;
                padding: 5px 14px 6px 14px;
                border-radius: 50px;
                background-color: $primary_color;
                font-size: $font_size_18;
                font-weight: 600;
                color: #ffffff;
              }

              .content {
                align-items: center;
                padding: 18px 9px;
                width: 178px;
                font-size: $font_size_16;
                font-weight: $font_weight_400;
                line-height: 20px;
                text-align: center;
                border-radius: 5px;
                border: solid 1px #00ca94;
                background-color: #e5faf4;
                color: #2a324b;
                margin: 12px 0 0;
                white-space: pre-wrap;
                word-break: keep-all;
              }
            }

            .graph {
              width: 414px;
              height: 400px;
              margin: 50px auto;
            }

            .result {
              display: flex;
              flex-direction: column;
              margin-top: 0px;
              padding: 0px;

              .header {
                font-size: $font_size_24;
                font-weight: bold;
                line-height: 20px;
                margin-top: 60px;
                letter-spacing: normal;
                text-align: center;
                color: #2a324b;
              }

              .sub-header {
                margin: 36px 36px 0px;
                font-size: $font_size_20;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: normal;
                text-align: center;
                color: #2a324b;
              }

              .body {
                display: flex;
                flex-direction: column;
                margin-top: 48px;

                .category {
                  &:nth-child(2n-1) {
                    background-color: rgba(0, 202, 148, 0.1);
                  }

                  .category-container {
                    display: flex;
                    flex-direction: column;
                    padding: 36px;

                    .name {
                      font-size: $font_size_20;
                      padding: 0px;
                      font-weight: 500;
                      text-align: left;
                      color: #2a324b;
                    }

                    .descriptions {
                      padding-top: 24px;
                      display: flex;
                      flex-direction: column;
                      flex: 1;
                      align-self: center;
                      margin: 0px;
                      color: #2a324b;
                      letter-spacing: normal;
                      text-align: left;
                      word-break: keep-all;
                      white-space: pre-wrap;

                      .description {
                        font-size: $font_size_18;
                        font-weight: $font_weight_400;
                        line-height: 1.33;
                      }

                      .guide-header {
                        margin-top: 24px;
                        border-top: dashed 1px #2a324b;
                        padding-top: 24px;
                        font-size: $font_size_16;
                        font-weight: 500;
                        line-height: 1.5;

                        img {
                          margin-right: 4px;
                        }
                      }

                      .category-guide {
                        font-size: $font_size_16;
                        font-weight: 500;
                        line-height: 1.5;
                        letter-spacing: normal;
                        margin-top: 7px;
                      }

                      .question-guides {
                        margin-top: 10px;
                        font-size: $font_size_16;
                        font-weight: 300;
                        line-height: 1.5;
                      }
                    }
                  }
                }
              }
            }

            .my-trial {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 0px;

              .bottom {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                background-color: #f7f8fa;

                .my-trial-box {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-top: 0px;
                  border-radius: 0px;
                  border: none;
                  width: 100%;

                  .img {
                    margin: 36px 24px 36px 36px;
                    background-image: url("~@/assets/images/my_trial_diagnosis.svg");
                    background-size: 80px 80px;
                    width: 80px;
                    min-width: 80px;
                    height: 80px;

                    &.solution {
                      background-image: url("~@/assets/images/img_dt_consulting.svg");
                    }

                    &.share {
                      background-image: url("~@/assets/images/img_dt_offering.svg");
                    }
                  }

                  .right {
                    font-size: $font_size_16;
                    line-height: 1.63;
                    letter-spacing: normal;
                    color: #2a324b;
                    margin: 36px 0;

                    .bold {
                      color: #2a324b;
                      font-size: $font_size_23;
                      font-weight: bold;
                      margin-bottom: 18px;
                    }

                    .button {
                      width: 100px;
                      height: 32px;
                      line-height: 32px;
                      background-color: #00ca94;
                      font-size: $font_size_13;
                      text-align: center;
                      cursor: pointer;
                      color: #ffffff;
                    }
                  }
                }

                .my-trial-box + .my-trial-box {
                  margin-left: 0px;
                }
              }

              .text-container {
                display: flex;
                margin: 18px 18px 48px;

                .retry-text {
                  width: 180px;
                  height: 53px;
                  line-height: 53px;
                  text-align: center;
                  background-color: #00ca94;
                  font-size: $font_size_16;
                  color: #ffffff;
                  cursor: pointer;
                  text-decoration: none;
                }

                .retry-text + .retry-text {
                  margin-left: 18px;
                }
              }
            }
          }

          .copyright {
            margin-top: 0px;
          }
        }
      }

      &.isSubmitted {
        height: 100%;
      }
    }

    .direction-buttons {
      position: absolute;
      bottom: 0px;
      display: flex;
      flex-direction: row;
      align-self: flex-end;
      width: 100%;
      height: 48px;
      background-color: #00ca94;

      .submit-button {
        width: 100%;
        height: auto;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
      }

      &.hide {
        background-color: #ffffff;
      }
    }
  }
}
</style>
<style lang="scss">
@import '~@/assets/style/variable.scss';

.toasted-container .toasted {
  background-color: #fff;
  top: 50%;
  align-self: center;

  .sally-trial-toast {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    letter-spacing: normal;
    color: #2a324b;

    .image {
      width: 20px;
      height: 20px;
      background-image: url("~@/assets/images/check-green-round.svg");
      background-size: 20px 20px;
      background-position: 0 0;
      margin-right: 8px;
    }
  }
}

.toasted-container.top-center {
  top: 100px;
}

.toasted-container.below-button {
  right: calc((100vw - #{$max_width}) / 2 + 24px);
  top: calc((100vh - 100%) / 2 + 195px);
}
</style>
