<template>
  <modal :name="name" class="sally-modal" :clickToClose="false" :height="height" :width="width"
    :adaptive="true" :maxHeight="807" :minHeight="_.min(minHeight, height)"
    @before-open="event => $emit('before-open', event)"
    @before-close="event => $emit('before-close', event)">
    <div class="header">
      <slot name="title">TITLE</slot>
    </div>
    <div class="body">
      <slot name="contents"></slot>
    </div>
    <div class="footer" v-if="$slots.buttons">
      <slot name="buttons"></slot>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'SallyModal',
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String || Number,
      default() {
        return '1180';
      },
    },
    height: {
      type: String || Number,
      default() {
        return '90%';
      },
    },
    minHeight: {
      type: Number,
      default() {
        return 600;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/style/variable.scss";

.sally-modal {
  .header {
    height: 93px;
    border-bottom: solid 1px #dadce0;
    background-color: #fdfdfd;
    display: flex;
    align-items: center;
    padding: 0 30px;
    font-size: $font_size_21;
    font-weight: bold;
    letter-spacing: normal;
    color: #2a324b;
  }
  .body {
    height: calc(100% - 93px - 68px)
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 32px;
    height: 68px;
    border-top: solid 1px #dadce0;
  }
}
</style>
<style lang="scss">
@import '~@/assets/style/components/sally-modal.scss';
.sally-modal {
  .footer {
    button {
      margin-right: 18px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
