<template>
  <modal name="submit-success-modal" class="submit-success-modal" height="391" width="426">
    <div class="submit-success-modal-header">
      <div class="header-image">
        <div class="sally-logo-light" />
        <div class="sally-logo-text-light" />
      </div>
    </div>
    <div class="submit-success-modal-body">
      <div class="message-container">
        <div>{{ $t('submit_success.content') }}</div>
        <br>
        <div>{{ $t('submit_success.thank') }}</div>
      </div>
    </div>
    <div class="submit-success-modal-footer">
      <button class="close-button" @click="hide">{{ $t('close') }}</button>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'SubmitSuccessModal',
  methods: {
    hide() {
      this.$modal.hide('submit-success-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.submit-success-modal {
  .submit-success-modal-header {
    display: flex;
    .header-image {
      margin: 61px auto 36px;
      display: flex;
      .sally-logo-light {
        width: 35px;
        height: 35px;
        background-size: 35px 35px;
        background-image: url('~@/assets/images/sally-logo-light.svg');
      }
      .sally-logo-text-light {
        width: 87px;
        height: 44px;
        background-size: 87px 44px;
        background-image: url('~@/assets/images/sally-logo-text-light.svg');
      }
      .sally-logo-light + .sally-logo-text-light{
        margin-left: 17px;
      }
    }
  }
  .submit-success-modal-body {
    .message-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        font-size: 16px;
        font-weight: 300;
        color: #2a324b;
      }
    }
  }
  .submit-success-modal-footer {
    margin-bottom: 60px;
    .close-button {
      margin: 36px auto 0px;
      display: flex;
      justify-content: center;
      width: 120px;
      height: 32px;
    }
  }
}
</style>
