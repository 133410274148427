import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from '@/common/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const createPdf = (filename, header, footer, content) => {
  const RATE = 5.4285714286;
  const PAGE_WIDTH = 210 * RATE; // width: 1140
  const PAGE_HEIGHT = 297 * RATE;
  const TOP_BOTTOM_MARGIN = 120;
  const LEFT_RIGHT_MARGIN = 0;
  const documentDefinitions = {
    pageSize: {
      width: PAGE_WIDTH,
      height: PAGE_HEIGHT,
    },
    pageOrientation: 'PORTRAIT',
    pageMargins: [LEFT_RIGHT_MARGIN, TOP_BOTTOM_MARGIN],
    header,
    footer,
    content,
    defaultStyle: {
      font: 'NotoSansCJKkr',
    },
  };
  const fonts = {
    NotoSansCJKkr: {
      normal: 'NotoSans-Regular.otf',
      bold: 'NotoSans-Bold.otf',
      italics: 'NotoSans-Regular.otf',
      bolditalics: 'NotoSans-Regular.otf',
    },
    Roboto: {
      normal: 'Roboto-Regular.ttf',
      bold: 'Roboto-Regular.ttf',
      italics: 'Roboto-Regular.ttf',
      bolditalics: 'Roboto-Regular.ttf',
    },
  };
  pdfMake.createPdf(documentDefinitions, null, fonts).download(filename);
};

export const convertFileToDataURL = url => new Promise((resolve) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
});

export default createPdf;
