export const TERMS_OF_PRIVATE_INFORMATION_TITLE = '개인정보 수집 및 이용에 관한 동의';
export const TERMS_OF_PRIVATE_INFORMATION_CONTENTS = `개인정보 수집 및 이용에 관한 동의
문의하기 서비스 이용을 위하여 아래의 개인정보 수집ㆍ이용에 대한 내용을 자세히 읽어 보신 후 동의 여부를 결정하여 주시기 바랍니다.
1. 개인정보의 수집 및 이용 목적 
고객 문의에 대한 답변 제공 및 현황관리
2. 수집하는 개인정보의 항목
- 필수정보 : 이름, 이메일, 연락처, 회사명
※ 이용자 편의를 위해 쿠키정보를 활용하고 있습니다.
3. 수집한 개인정보의 보유ㆍ이용기간 
개인정보 수집 및 이용에 관한 동의 후 2년간 개인정보를 보유하고 이후 해당 정보를 지체 없이 파기합니다. 단, 법률에 의해 보존의무가 있는 경우에는 법령이 지정한 일정기간 동안 보존합니다.
4. 귀하는 위와 같은 개인정보 수집ㆍ이용에 동의하지 않으실 수 있습니다. 
동의를 하지 않을 경우 문의하기 서비스가 제한될 수 있습니다.




※ 본 개인정보의 수집이용 등에 대한 상세한 내용은 삼성SDS 홈페이지(www.samsungsds.com)에 공개된 <개인정보처리방침>을 참조하시기 바랍니다.`;
export const TERMS_OF_TRANSFER_TITLE = '개인정보 해외이전에 관한 동의';
export const TERMS_OF_TRANSFER_CONTENTS = `개인정보 해외이전에 관한 동의
삼성에스디에스 주식회사(이하 '회사')는 아래와 같이 국외 제3자에게 개인정보를 제공하고 있으며 사용자가 거주하는 국가 이외의 지역에 있는 서버에서 이용자의 개인정보를 처리할 수 있습니다.
1. 이전되는 개인정보 항목 
- 필수정보 : 이름, 이메일, 연락처, 회사명


※ 이용자 편의를 위해 쿠키정보를 활용하고 있습니다.
※ 상기 필수정보 및 선택정보는 최초 수집된 정보뿐만 아니라 정보 수정으로 변경된 정보를 포함합니다. 
2. 개인정보가 이전되는 국가, 이전일시 및 이전방법
- 이전되는 국가 : 미국, 호주 
- 이전일시 및 이전방법 : 회사 대표사이트에 제공된 인터넷 관련 서비스 이용 시점에 네트워크를 통한 전송 
3. 개인정보를 이전받는자의 성명
업체명 : Amazon Web Service (미국) 
연락처 : https://aws.amazon.com/compliance/contact/
업체명 : Oracle Corporation Data Center (호주) 
연락처 : https://www.oracle.com/legal/privacy/services-privacy-policy.html
Chief Privacy Officer, Oracle Corporation
10 Van de Graaff Drive
Burlington, MA 01803
USA
4. 개인정보를 이전받는 자의 개인정보 이용목적 
회사는 하기의 서비스 제공하기 위해 반드시 필요한 업무를 외부업체로 하여금 수행하도록 개인정보를 이전하고 있으며, 이전 업체가 개인정보보호 관계 법령을 위반하지 않도록 관리‧감독하고 있습니다.
- 회사, 서비스/상품, 투자, 채용, 홈페이지 등 고객 문의에 대한 피드백 제공 및 관리
- 이벤트 및 기타 서비스 제공, 이메일 마케팅 활동
- 파트너 프로그램 지원등 고객 문의에 대한 피드백 제공 및 관리
- 이벤트 문의대응 , 이벤트 참석을 위한 본인 확인 및 고객의 니즈를 고려한 이벤트 준비 ,진행, 사후검토 및 관리 
5. 개인정보를 이전받는 자의 개인정보 보유ㆍ이용기간 
개인정보 수집 및 이용에 관한 동의 후 2년간 개인정보를 보유하고 이후 해당 정보를 지체 없이 파기합니다. 단, 법률에 의해 보존의무가 있는 경우에는 법령이 지정한 일정기간 동안 보존합니다.
6. 동의를 거부할 권리 및 동의 거부에 따른 불이익 
이용자는 정보 수집을 거부할 권리가 있습니다. 단, 동의를 거부할 경우 서비스가 제한될 수 있습니다.




※ 본 개인정보 해외이전에 대한 상세한 내용은 삼성SDS 홈페이지(www.samsungsds.com)에 공개된 <개인정보처리방침>을 참조하시기 바랍니다.`;
export const TERMS_OF_PROVIDE_TITLE = '삼성SDS 솔루션 및 이벤트 정보 수신에 관한 동의';
export const TERMS_OF_PROVIDE_CONTENTS = `삼성SDS 솔루션 및 이벤트 정보 수신에 관한 동의
삼성SDS 솔루션 출시, 이벤트, 뉴스레터를 받아보기 위하여 아래의 개인정보 수집*이용에 대한 내용을 자세히 읽어 보신 후 동의 여부를 결정하여 주시기 바랍니다.
1. 개인정보의 수집 및 이용 목적 
삼성SDS 솔루션 출시, 이벤트, 뉴스레터 이메일 수신 활용
2. 수집하는 개인정보의 항목 
- 필수정보 : 이름, 이메일, 연락처, 회사명

※ 이용자 편의를 위해 쿠키정보를 활용하고 있습니다.
3. 수집한 개인정보의 보유ㆍ이용기간
개인정보 수집 및 이용에 관한 동의 후 2년간 개인정보를 보유하고 이후 해당 정보를 지체 없이 파기합니다. 단, 법률에 의해 보존의무가 있는 경우에는 법령이 지정한 일정기간 동안 보존합니다.
4. 귀하는 위와 같은 개인정보 수집ㆍ이용에 동의하지 않으실 수 있습니다.
동의를 하지 않을 경우 삼성SDS 솔루션 및 이벤트 정보 수신이 제한될 수 있습니다.



※ 본 개인정보의 수집이용 등에 대한 상세한 내용은 삼성SDS 홈페이지(www.samsungsds.com)에 공개된 <개인정보처리방침>을 참조하시기 바랍니다.`;
