<template>
  <modal name="request-consulting-mobile-modal" class="request-consulting-mobile-modal"
         @before-open="beforeOpen" height="95%" top="30" width="356">
    <div class="request-consulting-modal-header">
      <img src="~@/assets/images/close-black.svg" class="close"
           @click="$modal.hide('request-consulting-mobile-modal')"/>
      <div class="header-image"/>
    </div>
    <div class="request-consulting-modal-divider">
      <div class="divider-title">{{ $t('request_consulting.title', { title: surveyTitle }) }}</div>
      <div class="divider-description">{{ $t('request_consulting.description') }}</div>
    </div>
    <div class="request-consulting-modal-body">
      <div class="info-content disable-field">
        <label>{{ $t('request_consulting.email') }}</label>
        <div>{{user.email}}</div>
      </div>
      <div class="info-content disable-field">
        <label>{{ $t('request_consulting.name') }}</label>
        <div>{{user.name}}</div>
      </div>
      <div class="info-content disable-field">
        <label>{{ $t('request_consulting.mobile') }}</label>
        <div>{{user.mobile}}</div>
      </div>
      <div class="info-content disable-field">
        <label>{{ $t('request_consulting.company_name') }}</label>
        <div>{{user.company}}</div>
      </div>
      <div>
        <sally-textarea
          class="info-textarea"
          label="Message"
          v-model="consultingMessage"
          :placeholder="$t('enter_message')"/>
        <button class="submit-button" @click.once="submitRequestConsultingMessage"
                :disabled="consultingMessage.length === 0">
          {{ $t('request_consulting.button_request') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SallyTextarea from '@/components/Common/SallyTextarea.vue';

export default {
  name: 'RequestConsultingMobileModal',
  props: {
    surveyTitle: String,
  },
  components: {
    SallyTextarea,
  },
  data() {
    return {
      consultingMessage: '',
      user: {
          name: '',
          company: '',
          mobile: '',
          email: '',
      },
    };
  },
  computed: {
    ...mapGetters(['mySubmissionResult']),
  },
  methods: {
    ...mapActions(['saveConsultingMessage']),
    beforeOpen({ params }) {
        if (params) {
            this.user = params;
        }
    },
    async submitRequestConsultingMessage() {
      const consultingParam = {
        ...this.user,
        comment: this.consultingMessage,
      };
      const data = await this.saveConsultingMessage(consultingParam);
      if (data.status === 200) {
        this.$modal.hide('request-consulting-mobile-modal');
        this.$emit('submitSuccessModal');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.request-consulting-mobile-modal {
  .request-consulting-modal-header {
    .close {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }
    .header-image {
      margin: 48px auto 48px;
      width: 62px;
      height: 69px;
      background-size: 62px 69px;
      background-image: url('~@/assets/images/img_request_consulting.svg');
    }
  }
  .request-consulting-modal-divider {
    background-color: rgba(0, 202, 148, 0.03);
    height: 99px;
    padding-top: 24px;
    .divider-title {
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      color: #00ca94;
    }
    .divider-description {
      font-size: 14px;
      font-weight: 300;
      text-align: center;
      color: #2a324b;
    }
  }
  .request-consulting-modal-body {
    padding: 36px 48px 40px;
    .disable-field {
      margin-top: 0;
      min-height: 20px;
      padding-top: 13px;
      margin-bottom: 18px;
      label {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        height: 13px;
        color: rgba(0, 0, 0, 0.42);
        font-size: 12px;
      }
      div {
        padding-bottom: 2px;
        font-size: $font_size_16;
        color: rgba(0, 0, 0, 0.87);
        height: 30px;
        line-height: 30px;
        border-bottom: solid 1px #dadce0;
      }
    }
    .info-content {
      width: 258px;
    }
    .info-textarea {
      border: none;
    }
    .message-title {
      height: 13px;
      color: rgba(0, 0, 0, 0.42);
      font-size: 12px;
    }
  }
  .submit-button {
    margin: 36px auto 0;
    display: flex;
    justify-content: center;
    min-width: 120px;
    height: 32px;
  }
}
</style>
